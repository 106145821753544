<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>섹터별통계</h1></div>
    <common-area-search
         :row="1"
        :colspan="3"
    >
      <template v-slot:R1_C1>
        <label>회사명</label>
        <ul>
          <common-multi-drop-down
              ref="cmpyCdRef"
              @change="value=>{
                  searchCmpyCd = value
                  searchLocaCd = ''
                  searchWhCd = ''
                  iframeSrc = ''
                  $refs.locaCdRef.widget().value('')
                  $refs.whRef.widget().value('')
                }"
              :params="{ 'sort[0].dir': 'asc', 'sort[0].field': 'cmpyNm',searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY] }"
              :default-index="0"
              :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>로케이션</label>
        <ul>
          <common-multi-drop-down
              ref="locaCdRef"
              :params="{ searchCmpyCd }"
              :disabled="searchCmpyCd===''"
              :all-params-validator="true"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/location`"
              :data-value-field="'locaCd'"
              :data-text-field="'locaNm'"
              @change="value=>{
                  searchLocaCd = value
                  searchWhCd = ''
                  iframeSrc = ''
                  $refs.whRef.widget().value('')
                }"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R1_C3>
        <label>구역</label>
        <ul>
          <common-multi-drop-down
              ref="whRef"
              :params="{ searchCmpyCd, searchLocaCd }"
              :disabled="searchCmpyCd==='' || searchLocaCd===''"
              :all-params-validator="true"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/wh`"
              :data-value-field="'whCd'"
              :data-text-field="'whNm'"
              @change="value=>{
                  searchWhCd = value
                  iframeSrc = ''
                  open3DView()
                }"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template #buttonArea>
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="open3DView">조회</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
      </template>
    </common-area-search>
    <div style="background:#fff; margin-top:10px; height:650px">
      <iframe
          v-show="!!iframeSrc"
          :src="iframeSrc"
          :width="'99.8%'"
          :height="650"
      ></iframe>
    </div>
  </div>
</template>

<script>
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from "@ebmp-fe-common-module/module/src/common/etners.common.util"
import Constant from '@/common/constant'

export default {
  name: 'ReportSectorList',
  methods : {
    open3DView : function (){
      const vm = this
      let model

      if(!vm.validatorSearch()) return

      let mode = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'DEV' : 'PROD'
      let domain = `${ mode === 'DEV' ? 'dev-' : ''}3d-view.etnersefs.com`
      let apiConId = `${mode === 'DEV' ? '2208110001' : '2210310001'}`
      let cmpyCd = `${mode === 'DEV' ? '10068' : '00003'}`
      let cmpyNm = vm.$refs.cmpyCdRef.widget().text()
      let locaNm = vm.$refs.locaCdRef.widget().text()
      let whNm = vm.$refs.whRef.widget().text()
      let viewInfo = {
        path : `https://${domain}/view/${apiConId}/${cmpyCd}/${cmpyNm}/${locaNm}/${whNm}`,
        query : {
          user : 'etners',
          company : vm.searchCmpyCd,
          location : vm.searchLocaCd,
          warehouse : vm.searchWhCd,
          mode: mode === 'DEV' ? 'dev' : 'prod',
        }
      }
      let query = ''
      for( let key in viewInfo.query){
        query += `${key}=${viewInfo.query[key]}&`
      }
      let url = `${viewInfo.path}?${query}`
      this.iframeSrc = url
      // EtnersEfsUtil.openWindowPopup(url,1200,600)

    },
    validatorSearch : function (){
      const vm = this

      if(EtnersCommonUtil.isEmpty(vm.searchCmpyCd)){
        kendo.alert("회사를 선택해주세요.").bind('close', function() {
          vm.$refs.cmpyCdRef.widget().focus()
          vm.$refs.cmpyCdRef.widget().toggle()
        })
        return false
      }
      if(EtnersCommonUtil.isEmpty(vm.searchLocaCd)){
        kendo.alert("로케이션을 선택해주세요.").bind('close', function() {
          vm.$refs.locaCdRef.widget().focus()
          vm.$refs.locaCdRef.widget().toggle()
        })
        return false
      }
      if(EtnersCommonUtil.isEmpty(vm.searchWhCd)){
        kendo.alert("구역을 선택해주세요.").bind('close', function() {
          vm.$refs.whRef.widget().focus()
          vm.$refs.whRef.widget().toggle()
        })
        return false
      }

      return true

    },
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 회사, 로케이션, 구역
       * */
      this.searchCmpyCd = ''
      this.searchLocaCd = ''
      this.searchWhCd = ''
      refs.cmpyCdRef.widget().value('')
      refs.locaCdRef.widget().value('')
      refs.whRef.widget().value('')

      this.iframeSrc = ''

    },
  },
  data () {
    return {
      Constant,
      iframeSrc : '',
      ApiConfig,
      searchCmpyCd : '',
      searchLocaCd : '',
      searchWhCd : '',

    }
  }
}
</script>

<style scoped>

</style>
